/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import '~@gonzaller/croppie/croppie.css';

ion-picker {
    touch-action: none;
}

.hide {
    display: none;
}

.multiline-select {
    .select-interface-option {
        .alert-radio-label {
            text-overflow: initial !important;
            white-space: break-spaces !important;
        }
    }
}

:root {
    --ion-color-primary: #488dc4;
    --ion-color-primary-rgb: 72, 141, 196;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3f7cac;
    --ion-color-primary-tint: #5a98ca;

    --ion-color-secondary: #91c842;
    --ion-color-secondary-rgb: 145, 200, 66;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #80b03a;
    --ion-color-secondary-tint: #9cce55;

    --ion-color-tertiary: #87b93a;
    --ion-color-tertiary-rgb: 135, 185, 58;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #77a333;
    --ion-color-tertiary-tint: #93c04e;

    --ion-color-success: #74b31b;
    --ion-color-success-rgb: 116, 179, 27;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #669e18;
    --ion-color-success-tint: #82bb32;

    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    --ion-color-danger: #f6511d;
    --ion-color-danger-rgb: 246, 81, 29;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #d8471a;
    --ion-color-danger-tint: #f76234;

    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
}
